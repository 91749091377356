.contenedor-capitulos video{
    width: 100%;
}
.contenedor-capitulos{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.contenedor-capitulos__capitulo{
    width: 500px;
    max-width: 700px;
    border: 3px solid var(--red);
    border-radius: 30px;
    padding: 10px 30px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.contenedor-capitulos__capitulo>.btn-generico{
    width: 100%;
}
.container-capitulo-video>.btn-generico{
    width: 100%;
}
.contenedor-capitulo__descripcion{
    width: 100%;
}
@media screen and (max-width: 859px) {
    .contenedor-capitulos__capitulo {
        margin-right: 0;
        width: 70%;
        margin-bottom: 10px;
    }
    .contenedor-capitulo__descripcion{
        width: 100%;
        height: 130px;
        overflow: scroll;
    }

}