:root {
  --red: #d1060e;
  --gray: #6c757d;
  --azulito: #f3f7fb;
  --softGray: #bfc2c8;
  --hardGray: #343a40;
  --softRed: #db3646;
  --hardBlue: #138496;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

header .logo {
  width: 130px;
  margin-left: 10px;
}
.contenedor-logout {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
}
.contenedor-logout button {
  width: 20%;
  margin-right: 230px;
}
h1 {
  text-align: center;
}
.Titulo-App {
  text-align: center;
  letter-spacing: 6px;
  font-size: 18px;
}

.Titulo-App > h2 {
  font-style: normal;
}

.Titulo-App > :first-child {
  color: var(--softGray);
  border-top: 3px solid var(--softGray);
  border-bottom: 3px solid var(--softGray);
  margin-top: 10px;
  margin-bottom: 0;
}

.Titulo-App > :nth-child(2) {
  color: var(--softGray);
  margin-top: 0;
}

.container {
  padding: 10px;
}

.btn-logout,
button,
.btn-generico {
  outline: none;
  font-size: 14px;
  border: 2px solid var(--red);
  background-color: white;
  padding: 10px 50px 10px 50px;
  border-radius: 0 0 30px 0;
  cursor: pointer;
}

.btn-enviar {
  background: white;
  margin-top: 30px;
  border: 2px solid var(--red);
  border-radius: 0 0 30px 0;
}

.input-file {
  border: none;
  outline: none;
}

.error-input {
  border-bottom: 1px solid var(--red);
  transition: 0.3s;
}

@media screen and (max-width: 859px) {
  h2 {
    font-size: 16px;
  }
  .link-visita {
    font-size: 16px;
  }
  .link > p {
    font-size: 16px;
  }
  .Titulo-App:first-child {
    display: flex;
    margin-bottom: 5px;
  }

  .Titulo-App {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .contenedor-logout {
    width: 100%;
    justify-content: center;
  }
  .contenedor-logout button {
    width: 80%;
    margin-right: 0;
  }
}
