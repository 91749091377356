form{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.form-group{
    width: 50%;
}
button{
    width: 30%;
}
.btn-enviar{
    width: 30%;
    margin-bottom: 15px;
}
.inputs-forms{
    margin-left: 30px;
}
@media screen and (max-width: 859px) {
    .btn-enviar{
        width: 100%;
    }
    .btn-generico{
        width: 100%;
    }
    #form-crearvisita>.form-group{
        width: 100%;
    }
    .inputs-forms{
        width: 100%;
        margin-left: 15px;
    }
}