.container-gestioncapitulos > .wrapper-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.container-gestioncapitulos > .wrapper-buttons > .btn-generico{
    margin-right: 10px;
    width: 20%;
}

@media screen and (max-width: 859px) {
    .container-gestioncapitulos > .wrapper-buttons > .btn-generico{
        width: 60%;
        margin-bottom: 10px;
    }
}