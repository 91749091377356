.container-enviado,
.box-enviado,
.contenedor-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contenedor-box {
  width: 50%;
}
.text-center {
  text-align: center;
}
.box-enviado {
  -webkit-box-shadow: 0px 3px 17px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 17px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0 3px 17px 2px rgba(0, 0, 0, 0.75);
  width: 60%;
  text-align: center;
  padding: 20px 40px 20px 40px;
  font-weight: 500;
  font-size: 15px;
  color: var(--gray);
}
.box-enviado img {
  width: 50%;
}
.parrafo-info {
  padding-left: 70px;
  padding-right: 70px;
}
.container-botones {
  display: flex;
  width: 60%;
  padding: 10px 40px 10px 40px;
  margin-top: 30px;
  justify-content: space-between;
}
span {
  color: var(--hardGray);
}
.btn-home,
.btn-logout {
  background-color: #17a2b7;
  border-radius: 30px;
  border: none;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 300;
}
.btn-logout {
  background-color: var(--softRed);
}
.btn-home:hover {
  background-color: var(--hardBlue);
  transition: 0.3s;
}
.btn-logout:hover {
  background-color: var(--red);
  transition: 0.3s;
}
.contenedor-buton-p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contenedor-buton-p {
  position: relative;
}
.contenedor-buton-p .ImagenFlecha {
  position: absolute;
  width: 4em;
  left: 0em;
  bottom: 0em;
}
.contenedor-buton-p p {
  text-align: center;
  color: var(--gray);
}
.container-botones button {
  width: 45%;
  padding: 20px;
}
.container-botones > div {
  width: 50%;
}
.contenedor-buton-p > button {
  width: 80%;
}
.container-botones {
  align-items: flex-end;
}
@media screen and (max-width: 859px) {
  .contenedor-box {
    width: 100%;
  }
  .box-enviado {
    width: 100%;
    padding: 0;
  }
  .container-botones {
    width: 100%;
    align-items: flex-end;
  }
  .container-botones button {
    width: 45%;
    padding: 20px;
  }
  .container-botones > div {
    width: 50%;
  }
  .contenedor-buton-p > button {
    width: 80%;
  }
  .contenedor-buton-p p {
    text-align: center;
    color: var(--gray);
    font-size: 14px;
  }
}
