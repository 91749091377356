.container-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h2 {
  text-align: center;
  font-style: italic;
  font-weight: 500;
  color: var(--gray);
}
a {
  text-decoration: none;
  font-size: 1.3em;
  color: var(--gray);
  font-style: italic;
}
a b {
  color: var(--hardGray);
}
b {
  line-height: 10px;
}
.link .border-blue {
  cursor: pointer;
}
.link-visita {
  cursor: pointer;
  width: 50%;
  outline: none;
  background-color: white;
  padding: 20px;
  border: 2px solid red;
  border-radius: 0 0 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.strong {
  color: var(--hardGray);
  font-weight: bold;
}
.link {
  padding: 5px;
  border: 1px solid var(--softGray);
  border-radius: 0 0 30px 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hardGray);
  font-size: 1em;
  margin-bottom: 15px;
}
.link img {
  width: 8%;
  margin-right: 10px;
}

.container-visita > .btn-finalizar {
  padding: 10px;
  cursor: pointer;
}
.contenedor-logout > .btn-logout {
  background-color: white;
  color: var(--hardGray);
  border-radius: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 15%;
}
.contenedor-logout > .btn-logout > span {
  margin-left: 5px;
  background: var(--red);
  padding: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.contenedor-logout > .btn-logout:hover {
  background-color: white;
  border-bottom: 3px solid var(--softRed);
}
.border-blue {
  border: 3px solid rgb(75, 159, 180);
  text-align: center;
}
.border-blue {
  position: relative;
}
.border-blue .flecha-border {
  position: absolute;
  left: -2em;
  width: 8em;
}
@media screen and (max-width: 859px) {
  .link-visita,
  .link {
    width: 70%;
  }
  .link {
    font-size: 1.2em;
  }
  .link img {
    width: 15%;
  }
  .contenedor-logout {
    margin-top: 0;
  }
  .contenedor-logout > .btn-logout {
    width: 100%;
  }
  .border-blue .flecha-border {
    position: absolute;
    width: 3.2em;
  }
}
