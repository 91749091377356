.container-complete{
    display: flex;
    justify-content: center;
}
.box-complete{
    -webkit-box-shadow: 0px 3px 17px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 17px 2px rgba(0,0,0,0.75);
    box-shadow: 0 3px 17px 2px rgba(0,0,0,0.75);
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}
.box-complete h1{
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 30px;
}

@media screen and (max-width: 859px) {
    .box-complete>.btn-generico{
        width: 50%;
    }
    .box-complete h1{
        font-size: 16px;
    }
}