.container-popup {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.71);
    justify-content: center;
}

.contenedor-componente {
    background-color: white;
    border-radius: 30px;
    height: 93%;
    width: 70%;
    margin-top: 30px;
}

.container-popup > .btn-cerrar {
    position: absolute;
    width: 30px;
    cursor: pointer;
    top: 50px;
    right: 230px;
}

@media screen and (max-width: 859px) {
    .container-popup > .btn-cerrar {
        right: 20px;
        top: 50px;
        width: 40px;
    }
    .contenedor-componente{
        width: 85%;
        height: 85%;
        padding: 0 20px;
    }
}