.table-generica {
    width: 100%;
    margin-top: 40px;
    border-collapse: collapse;
    border-bottom: 3px solid var(--softRed);
    border-spacing: 0;
}

.table-generica .form-group h3 {
    margin-right: 10px;
}

td {
    text-align: center;
}
.th-big{
    width: 30px;
}
.cabecera {
    height: 50px;
    border: 3px solid var(--softRed);
    background-color: #F75E5E;
}
.list-pagination{
    display: flex;
    list-style: none;
    justify-content: flex-end;
}
.list-pagination li{
    margin-right: 20px;
    cursor: pointer;
}
.list-pagination li:hover{
    border-bottom: 3px solid var(--softRed);
    transition: .3s;
}
.fila-registro {
    height: 80px;
    border: 3px solid var(--softRed);
    border-bottom: none;
}
.fila-registro:hover{
    background-color: #F2F2F2;
    transition: .3s;
    cursor: pointer;
}
.opcion-tabla{
    padding-bottom: 5px;
}
.opcion-tabla:hover{
    border-bottom: 3px solid var(--softRed);
    transition: .3s;
}
@media screen and (max-width: 859px){
   .table-generica{
       display: block;
       overflow-x: scroll;
   }
    td{
        padding: 10px;
    }
}