.contenedor-opcion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 859px){

    #form-opciones >h1{
        font-size: 24px;
    }
    .contenedor-opcion{
        padding: 0 30px;
    }
}