.container-questions h1{
    text-align: center;
    color: var(--gray);
}
form{
    justify-content: left;
    align-items: normal;
    padding-left: 10px;
    color: var(--gray);
}
.form-questions>p{
    width: 100%;
}
.form-questions .form-group{
    flex-direction: row;
    justify-content: normal;
    align-items: normal;
    width: 100%;
}
.radio{
    width: auto;
}
.form-questions .btn-finalizar{
    background-color: white;
    padding: 15px;
    font-size: 1.2em;
    cursor: pointer;
    width: 100%;
}
.container-btn{
    width: 20%;
    display: flex;
    justify-content: center;
}
.btn-atras{
    cursor: pointer;
    width: 20%;
    margin-top: 10px;
}
@media screen and (max-width: 859px){
    form{
       padding: 0;
    }
    .container-questions h1{
        font-size: 24px;
    }
    .form-questions .btn-finalizar{
        width: 100%;
    }
    .container-btn{
        width: 100%;
    }
}