.container-solicitud{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form{
    width: 50%;
    align-items: center;
    justify-content: center;
}
.form-group{
    justify-content: center;
    align-items: center;
}
input{
    width: 100%;
}
select{
    width: 100%;
}
.btn-enviar{
    cursor: pointer;
}
.politica{
    padding: 0 30px 0 30px;
    color: #0C5460;
    background-color: #D1ECF1;
    border-radius: 5px;
    border:1px solid #b5d5d9;
}
.container-radio{
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.container-radio a{
    font-style: normal;
    font-size: 16px;
}
.container-radio a:hover{
    transition: .3s;
    border-bottom: 1px solid var(--gray);
}
.button{
    display: flex;
    align-items: center;
}
.button input{
    width: auto;
}
