.contenedor-radios{
    display: flex;
    flex-direction: column;
}
.contenedor-radios >.form-group{
    display: flex;
    justify-content: normal;
    align-items: center;
}
.contenedor-radios>.form-group>input{
    width: auto;
    margin-right: 10px;
}
#form-crear-preguntas>.btn-generico{
    margin-top: 20px;
}
@media screen and (max-width: 859px){
    .showpopup{
        width: 50%;
        text-align: center;
    }
    #form-crear-preguntas>.btn-generico{
        margin-top: 20px;
        width: 50%;
        text-align: center;
    }
    #form-crear-preguntas>.enviar{
        width: 80%;
    }
}