.modal-form{
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    display: none;
}
.show{
    display: flex;
}
.contenedor-modal{
    width: 40%;
    height: 350px;
    top: 15%;
    margin: 10% auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    -webkit-box-shadow: 0px 3px 17px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 17px 2px rgba(0,0,0,0.75);
    box-shadow: 0 3px 17px 2px rgba(0,0,0,0.75);
    border-radius: 10px;
    background-color: white;
    padding: 10px 40px 30px 40px;

}
.footer{
    width: 100%;
    word-wrap: break-word;
}
.parrafo-modal{
    padding-bottom: 10px;
}
.modal-form h1{
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
}
.contenedor-cerrar{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.img-cerrar{
    justify-content: right;
    width: 8%;
    cursor: pointer;
}
.contenedor-stop{
    width: 100%;
    display: flex;
    justify-content: center;
}
.img-stop{
    width: 20%;
}

@media screen and (max-width: 859px) {
    .contenedor-modal{
        margin: 30% auto;
        width: 60%;
    }
    .img-cerrar{
        position: relative;
        right: -20px;
        width: 15%;
    }
}