.container-modulo-admin >h1{
    text-align: center;
}

.btn-generico{
    font-style: normal;
    color: var(--hardGray);
}
.wrapper-modulo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 130px;
}
.wrapper-modulo>.btn-generico{
    margin-right: 20px;
}
@media screen and (max-width: 859px) {
    .wrapper-modulo{
        margin-top: 0;
    }
    .wrapper-modulo>.btn-generico{
        margin-right: 0px;
        margin-top: 20px;
        text-align: center;
        width: 50%;
    }
}