.contenedor-crear-capitulo{
    width: 100%;
}
#form-capitulo .form-group input{
    width: 30%;
}
#form-capitulo .form-group{
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.input-file{
    display: none;
}
label{
    cursor: pointer;
}
label:hover{
    border-bottom: 1px solid var(--hardGray);
}
.contenedor-btn-capitulo{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contenedor-btn-capitulo>.btn-generico{
    width: 30%;
}
.barra{
    background-color: #f3f3f3;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    height: 25px;
    width: 50%;
}
.barraazul{
    background-color: var(--red);
    border-radius: 10px;
    display: block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 0%;
}
.barraverde{
    background-color: #2EA265;
}
#barraestado span{
    color: #fff;
    font-weight: bold;
    line-height: 25px;
}
@media screen and (max-width: 859px){
    #form-capitulo .form-group .btn-enviar{
        width: 100%;
    }
    .contenedor-btn-capitulo>.btn-generico{
        width: 100%;
    }
    .barra{
        width: 100%;
    }
}