.container-visita{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.container-cap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-cap p{
    padding: 0 60px 0 60px;
    text-align: center;
    color: var(--gray);
}
.container-cap video{
    width: 70%;
}
.container-buttons{
    width: 50%;
}
.container-buttons{
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
}
.container-buttons img{
    width: 10%;
    cursor: pointer;
}
.btn-finalizar{
    border: 2px solid var(--red);
    border-radius: 0 0 30px 0;
    padding: 5px;
    font-style: normal;
    text-align: center;
    margin-top: 20px;
    width: 20%;
    outline: none;
}

@media screen and (max-width: 859px) {
    .container-cap video{
        width: 100%;
    }
    .container-cap p{
        padding: 0;
        width: 100%;
        height: 200px;
        overflow: scroll;
    }
    .btn-finalizar{
        width: 80%;
    }
}