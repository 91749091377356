
.contenedor-login{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--gray);
    text-align: center;
}
#form-login>.text-special{
    font-size: 15px;
    padding-bottom: 3px;
}
#form-login>.text-special:hover{
    transition: .3s;
    border-bottom: 1px solid var(--gray);
}
h1{
    margin-top: 40px;
    font-size: 40px;
    font-weight: 700;
}
form{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form-group{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.form-group img{
    width: 50px;
    margin-right: 10px;
}
input,select{
    width: 40%;
    padding: 10px;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--gray);
    border-radius: 0 0 5px 0;
}
.input-submit{
    margin-top: 10px;
    border: 2px solid var(--red);
    background-color: white;
    border-radius: 0 0 30px 0;
    cursor: pointer;
}
@media screen and (max-width: 859px){
    input{
        width: 65%;
    }
}