.pop-video{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    display: flex;
    background: rgba(0,0,0,0.71);
    justify-content: center;
}
.pop-video video{
    width: 40%;
    outline: none;
}
.pop-video >.btn-cerrar{
    position: absolute;
    width: 30px;
    cursor: pointer;
    top: 5px;
    right: 200px;
}

@media screen and (max-width: 859px) {
    .pop-video video{
        width: 70%;
        outline: none;
    }
    .pop-video >.btn-cerrar{
        right: 10px;
        width: 50px;
    }
}